import { camerasConstants } from "../constants";

export let initState = {
  cameraData: {
    data: [],
  },
  packageData: {
    data: [],
  },
  subscriptionData: {},
  loaders: {
    cameras: false,
    packages: false,
    subscription: false,
    plans: false,
    subscribe: false,
  },
  plans: [],
  errors: {
    cameras: {},
    packages: {},
    subscription: {},
    subscribe: {},
  },
};

export const camerasReducer = (state = initState, action) => {
  switch (action.type) {
    // get cameras
    case camerasConstants.GET_CAMERAS_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, cameras: true },
      };
    case camerasConstants.GET_CAMERAS_SUCCESS:
      return {
        ...state,
        cameraData: action?.payload?.data,
        loaders: { ...state.loaders, cameras: false },
      };
    case camerasConstants.GET_CAMERAS_ERROR:
      return {
        ...state,
        errors: { ...state.errors, cameras: action.payload.data?.message },
        loaders: { ...state.loaders, cameras: false },
      };

    // get packages
    case camerasConstants.GET_PACKAGE_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, packages: true },
      };
    case camerasConstants.GET_PACKAGE_SUCCESS:
      return {
        ...state,
        packageData: action?.payload?.data,
        loaders: { ...state.loaders, packages: false },
      };
    case camerasConstants.GET_PACKAGE_ERROR:
      return {
        ...state,
        errors: { ...state.errors, packages: action.payload.data?.message },
        loaders: { ...state.loaders, packages: false },
      };

    // get packages
    case camerasConstants.GET_PACKAGE_PLANS_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, plans: true },
      };
    case camerasConstants.GET_PACKAGE_PLANS_SUCCESS:
      return {
        ...state,
        plans: action?.payload?.data,
        loaders: { ...state.loaders, plans: false },
      };
    case camerasConstants.GET_PACKAGE_PLANS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, plans: false },
      };

    // get subscriptions
    case camerasConstants.GET_SUBSCRIPTIONS_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, subscription: true },
      };
    case camerasConstants.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionData: action?.payload?.data,
        loaders: { ...state.loaders, subscription: false },
      };
    case camerasConstants.GET_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        errors: { ...state.errors, subscription: action.payload.data?.message },
        loaders: { ...state.loaders, subscription: false },
      };

    // add packages
    case camerasConstants.ADD_PACKAGE_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, subscribe: true },
      };
    case camerasConstants.ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        packageData: action?.payload?.data,
        loaders: { ...state.loaders, subscribe: false },
      };
    case camerasConstants.ADD_PACKAGE_ERROR:
      return {
        ...state,
        errors: { ...state.errors, subscribe: action.payload.data?.message },
        loaders: { ...state.loaders, subscribe: false },
      };

    default:
      return state;
  }
};
