export const scrollToPositionFrom = (source,destination) => {
    let id
    destination -= 120;
    if (destination<0) destination=0
    if(destination > source){
        id = setInterval(increase, 5);
    }else{
        id = setInterval(decrease, 5);
    }
    function increase() {
        if (source >= destination) {
            clearInterval(id);
        } else {
            source+=5;
            window.scrollTo(0,source);
        }
    }
    function decrease() {
        if (destination >= source) {
            clearInterval(id);
        } else {
            source-=5;
            window.scrollTo(0, source);
        }
    }
}