import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearAll } from "../../state/actions/login-actions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_URL } from "../../constants";
import BrandLogo from "../../assets/image/brandlogo.png";

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAll());
  }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_URL}>
      <div className="flex h-screen items-center justify-center main-background backdrop-blur">
        <div className="md:min-w-[600px] xs:min-w-full">
          <img src={BrandLogo} alt="logo" className="w-40 mx-auto !my-6" />
          <div className="w-full md:mt-0 sm:max-w-md xl:p-0 mx-auto">
            {children}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default AuthWrapper;
