/* eslint-disable no-undef */
export const Config = {
  BaseUrl: process.env.REACT_APP_BASE_URL,
  AuthUrl: process.env.REACT_APP_AUTH_URL,
  AuthUrls:process.env.REACT_APP_AUTH_URLS,
  GoogleClientURL: process.env.REACT_APP_GOOGLE_LOGIN,
  FbClientURL: process.env.REACT_APP_FB_LOGIN,
  NodeEnv: process.env.REACT_APP_NODE_ENV,
  WlinkOauthAuthUrl:process.env.REACT_APP_WLINK_OATH_AUTHOR_URL,
  WlinkRedirect:process.env.REACT_APP_WLINK_REDIRECT
};
