import { profileConstants } from "../constants";

export let initState = {
  processing: false,
  passwordData: {
    newPassword: "",
    confirmPassword: "",
  },
  updateProfileData:{
    processing: false,
    name: "",
    email: "",
    username: "",
    phone:"",
    address:""
  },
  profileData: {
    name: "",
    email: "",
    username: "",
    avatar: "",
    user_roles: [],
  },
  loaders: {
    profile: false,
    notifications: false,
    seen: false,
    policy: false,
  },
  policy: {},

  fcm_token: "",
  new_notification: false,
  notifications: {
    data: [],
  },

  error: {},
  serverError: false,
  pageLoading: true,
};

export const profileReducer = (state = initState, action) => {
  switch (action.type) {
    // login Reducers
    case profileConstants.CHANGE_PASSWORD_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case profileConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordData: initState.passwordData,
        processing: false,
      };
    case profileConstants.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        error: { ...action.payload.data?.message },
        processing: false,
      };

      case profileConstants.UPDATE_PROFILE_DATA_PROCESSING:
        return {
          ...state,
          processing: true,
        };
      case profileConstants.UPDATE_PROFILE_DATA_SUCCESS:
        return {
          ...state,
          updateProfileData: initState.updateProfileData,
          processing: false,
        };
      case profileConstants.UPDATE_PROFILE_DATA_ERROR:
        return {
          ...state,
          error: { ...action.payload.data?.message },
          processing: false,
        };


    case profileConstants.CHANGE_PASSWORD_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case profileConstants.GET_PROFILE_DATA_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, profile: true },
      };

    case profileConstants.GET_PROFILE_DATA_SUCCESS:
      localStorage.setItem("role", action.payload?.data?.role?.name);
      return {
        ...state,
        profileData: action.payload.data,
        loaders: { ...state.loaders, profile: false },
      };

    case profileConstants.GET_PROFILE_DATA_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, profile: false },
      };

    case profileConstants.SET_SERVER_ERROR:
      return {
        ...state,
        serverError: action.payload,
      };

    case profileConstants.POST_FCM_TOKEN_SUCCESS:
      return {
        ...state,
        fcm_set: true,
      };

    // notifications
    case profileConstants.GET_NOTIFICATIONS_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, notifications: true },
      };
    case profileConstants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
        loaders: { ...state.loaders, notifications: false },
      };
    case profileConstants.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: { ...action.payload.data?.message },
        loaders: { ...state.loaders, notifications: false },
      };

    // notification seen
    case profileConstants.SET_SEEN_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, seen: true },
      };
    case profileConstants.SET_SEEN_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, seen: false },
      };
    case profileConstants.SET_SEEN_ERROR:
      return {
        ...state,
        error: { ...action.payload.data?.message },
        loaders: { ...state.loaders, seen: false },
      };

    // privacy policy
    case profileConstants.GET_PRIVACY_POLICY_PROCESSING:
      return {
        ...state,
        loaders: { ...state.loaders, policy: true },
      };
    case profileConstants.GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload.data,
        loaders: { ...state.loaders, policy: false },
      };
    case profileConstants.GET_PRIVACY_POLICY_ERROR:
      return {
        ...state,
        error: { ...action.payload.data?.message },
        loaders: { ...state.loaders, policy: false },
      };

    case profileConstants.SET_FCM_TOKEN:
      return {
        ...state,
        fcm_token: action.payload,
      };
    case profileConstants.SET_NEW_NOTIFICATION:
      return {
        ...state,
        new_notification: action.payload,
      };

    case profileConstants.SET_NOTIFICATION_DATA:
      return {
        ...state,
        notifications: { ...state.notifications, data: action.payload },
      };

    case profileConstants.SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.payload,
      };
    default:
      return state;
  }
};
