import Body from "./layouts/Body";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./state/actions/axios";
import Navigation from "./components/fileManager/Navigation/Navigation";

function App() {


  return (
    <Router>
      <Navigation/>
      <div className="flex bg-gray-50 min-h-screen">
        <div className="flex-grow text-gray-800 w-[calc(100vw-15rem)] ">
          <Body />
        </div>
      </div>
      <ToastContainer
        hideProgressBar
        position="bottom-right"
        autoClose={5000}
      />
    </Router>
  );
}

export default App;
