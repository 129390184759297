import moment from "moment";

export const convertToDays = (value) => {
  // takes value in seconds and converts to days
  const seconds = Number(value);
  const days = Math.floor(seconds / (3600 * 24));

  return days;
};

export const getExpiryDate = (value, date = "") => {
  // takes value in seconds, if date is not passsed takes todays date
  const seconds = Number(value);
  const days = Math.floor(seconds / (3600 * 24)) - 1;
  let expiry = date ? new Date(date) : new Date();
  expiry.setDate(expiry.getDate() + Number(days));
  return expiry;
};

export const addDaysToDate = (date, days) => {
  let expiry = date ? new Date(date) : new Date();
  expiry.setDate(expiry.getDate() + Number(days));
  return expiry;
};

export const calculateRemainingDays = (expiry, date, notInclToday) => {
  // takes expiry date and calcutes number of days difference from today
  let days = 0;
  let today = date
    ? moment(date).format("YYYY-MM-DD")
    : moment(moment(new Date()).format("YYYY-MM-DD"));
  let expiryDay = moment(expiry);
  days = expiryDay.diff(today, "days");
  if (expiry === null) {
    return 0;
  } else {
    return days;
  }
};

export const calculateRemainingDaysNotToday = (expiry, date, notInclToday) => {
  // takes expiry date and calcutes number of days difference from today
  let days = 0;
  let today = date
    ? moment(date).format("YYYY-MM-DD")
    : moment(moment(new Date()).format("YYYY-MM-DD"));
  let expiryDay = moment(expiry);
  days = expiryDay.diff(today, "days");
  return days;
};

export const calculateNewExpiry = (renew, expiry) => {
  // takes renew and expiry date and calculates new expiry date
  let days = 0;
  let val = new Date(expiry);
  let today = moment(moment(new Date()).format("YYYY-MM-DD"));
  let newDay = moment(renew);
  days = today.diff(newDay, "days");
  val.setDate(new Date(expiry).getDate() + Number(days));
  return val;
};

export const getStatusLabel = (value, expiry_date) => {
  let label = "";

  if (calculateRemainingDays(expiry_date) <= 0) {
    label = "Expired";
  } else {
    if (value === "grace" || value === "extend") {
      label = value;
    } else if (
      value === "paid" ||
      value === "switch" ||
      value === "renew" ||
      value === "paid-extend"
    ) {
      label = "Active";
    } else if (value === "pending") {
      label = "Expired";
    } else {
      label = value;
    }
  }

  return label;
};

export const getTimeDifference = (value) => {
  const now = new Date();
  const old = new Date(value);
  let seconds = (now.getTime() - old.getTime()) / 1000;
  let minutes = seconds / 60;

  return { seconds, minutes };
};

export const getDayRemainingLabel = (status, expiry) => {
  return (
    <>
      {status === "pending" ? (
        <span className={`ml-2 text-red-400`}>0 days remaining </span>
      ) : (
        <span
          className={`ml-2 ${
            calculateRemainingDays(expiry) > 0
              ? "text-green-600"
              : "text-red-400"
          }`}
        >
          {calculateRemainingDays(expiry) > 0
            ? `${calculateRemainingDays(expiry)} days remaining`
            : calculateRemainingDays(expiry) == 0
            ? "Expired 1 day ago"
            : `Expired ${(Number(calculateRemainingDays(expiry)) - 1)
                .toString()
                .replace("-", "")} days ago`}
        </span>
      )}

      {/* 
      {status === "pending" ? (
        <span className={`ml-2 text-red-400`}>0 days remaining </span>
      ) : (
        <span
          className={`ml-2 ${
            calculateRemainingDays(expiry) > 0
              ? "text-green-600"
              : "text-red-400"
          }`}
        >
          {calculateRemainingDays(expiry) > 0
            ? `${calculateRemainingDays(expiry)} days remaining`
            : "Expired"}
        </span>
      )} */}
    </>
  );
};
