import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Input, PasswordInput } from "../../components/Inputs";
import { resetPassword } from "../../state/actions/login-actions";
import { loginConstants } from "../../state/constants";
import AuthWrapper from "./AuthWrapper";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [companyCodeError, setCompanyCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email,setEmail]= useState(localStorage.getItem("email"));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const token = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const registerUser = location.pathname.includes("registration");
  const { error, loading } = useSelector((state) => ({
    error: state.login?.error?.data,
    loading: state.login?.processing,
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    if (!token) navigate("/login");
    if (companyCode.trim() === "") {
      setCompanyCodeError("This field is required.");
    }
    else if(email.trim === ""){
      navigate("/login")
    }
    else {
      const data = {
        email:email,
        password: password,
        password_confirm: confirmPassword,
      };
      // const api = registerUser
      //   ? loginConstants.REGISTRATION_API
      //   : loginConstants.RESET_API;

      // dispatch(resetPassword(api, data, navigate, companyCode));
      dispatch(resetPassword(data,navigate,companyCode));
    }
    localStorage.removeItem("email");
  };

  return (
    <AuthWrapper>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-[#009581] md:text-2xl">
          Change Password
        </h1>
        <form onSubmit={onSubmit} className="space-y-4 md:space-y-6">
          <Input
            // label={"Company Code"}
            label={"Otp Code"}
            value={companyCode}
            onChange={(e) => setCompanyCode(e.target.value)}
            error={companyCodeError}
          />
          <Input
            label={"Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            error={emailError}
          />
          <div>
            <label
              htmlFor="new-password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              New Password
            </label>
            <PasswordInput
              value={password}
              handleChange={(e) => setPassword(e.target.value)}
              error={error?.password?.[0]}
              data-testid="new"
              placeholder="New Password"
            />
          </div>
          <div>
            <label
              htmlFor="confirm-password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Confirm Password
            </label>
            <PasswordInput
              value={confirmPassword}
              handleChange={(e) => setConfirmPassword(e.target.value)}
              error={error?.password_confirm?.[0]}
              data-testid="confirm"
              placeholder="Confirm Password"
            />
          </div>
          <button
            type="submit"
            disabled={password !== confirmPassword}
            onClick={onSubmit}
            data-testid="change"
            className="w-full text-white bg-blue-500 hover:bg-blue-600 hover:transition-all focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:cursor-not-allowed disabled:bg-blue-400"
          >
            {loading ? "Changing password..." : "Change Password"}
          </button>
          <button className="mx-auto w-full !mt-3">
            <Link
              className="text-sm font-medium text-blue-600 hover:underline"
              to="/login"
            >
              Back to Login?
            </Link>
          </button>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
