import { errorAlert } from "../actions/alert-actions";
import { successAlert } from "../actions/alert-actions";
import axios from "axios";
import store from "../store";
import isEmpty from "is-empty";
import jwtDecode from "jwt-decode";
// import { handleLogout } from '../helpers';
import { Config } from "../../Config";
import { toast } from "react-toastify";
import { TENANT_ID } from "../../constants";
import { setServerError } from "./profile-actions";
import LoadingDiv from "../../components/LoadingDiv";

export const api = axios.create({
  baseURL: Config.AuthUrl,
});

let baseURL = Config.AuthUrl;

let refreshing = false;
let refreshSubscribers = [];

axios.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("_Bearer");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let refreshCount = 0;

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    const { config } = error;
    const originalRequest = config;
    if (refreshCount > 20) {
      store.dispatch(errorAlert("You have been logged out"));
      logOutHandler();
    } else if (parseInt(error.response?.status) === 401) {
      refreshCount += 1;
      if (
        !refreshing &&
        !localStorage.getItem("rt") &&
        !localStorage.getItem("_Bearer")
      ) {
        return Promise.reject(error.response);
      }
      if (!refreshing && localStorage.getItem("rt")) {
        refreshing = true;
        let config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        fetch(baseURL + `/subscribers/authentication/v1/refresh-token`, {
          method: "PATCH",
          ...config,
          body: JSON.stringify({ refresh_token: localStorage.getItem("rt") }),
        })
          .then((res) => {
            if (res.status === 200) {
              refreshing = false;
            }
            if (res.status === 401) {
              localStorage.removeItem("_Bearer");
              localStorage.removeItem("rt");
              refreshing = false;
              store.dispatch(
                errorAlert("You have been logged out, invalid token")
              );
              window.location.href = "/login";
              return;
            }
            return res.json();
          })
          .then((json) => {
            refreshing = false;

            onRrefreshed(json.access_token);
            setAuthToken(json.access_token, json.refresh_token);
            refreshSubscribers = [];
            return json;
          })
          .catch((err) => {
            if (err.status === 500) {
              store.dispatch(errorAlert("Server error"));
              store.dispatch(setServerError(true));
              window.location.href = "/login";
              return;
            } else {
              localStorage.removeItem("_Bearer");
              localStorage.removeItem("rt");
              store.dispatch(
                errorAlert("You have been logged out, invalid token")
              );
              window.location.href = "/login";
              return;
            }
          });
      }

      const retryOrigReq = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers["Authorization"] = "Bearer " + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }
    if (error?.response?.status === 521) {
      store.dispatch(errorAlert(error?.response?.data?.message));
    } else if (
      error?.response?.status &&
      error.response.status !== 422 &&
      error.response.status !== 404
    ) {
      if (error?.response?.data?.message) {
        store.dispatch(
          errorAlert(JSON.stringify(error?.response?.data?.message))
        );
      }
    }
    return Promise.reject(error.response);
  }
);

export const logOutHandler = () => {
  sessionStorage.setItem("redirect", window.location.pathname);
  localStorage.removeItem("_Bearer");
  localStorage.removeItem("rt");
  localStorage.removeItem("role");
  window.location.reload();
};

export const success = (type, payload) => {
  refreshCount = 0;
//   if (payload && payload.status === 201) {
//     payload.status = 200;
// }
  return { type: type, payload };
};

export const failure = (type, payload) => {
  return { type: type, payload };
};

export const processing = (type, payload) => {
  return { type: type, payload };
};

function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

export const isTokenExpired = () => {
  let token = localStorage.getItem("_Bearer");
  if (!isEmpty(token) && jwtDecode(token).exp < Date.now() / 1000) {
    return true;
  }
  return false;
};

export const setAuthToken = (token, rt) => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    localStorage.setItem("_Bearer", token);
    localStorage.setItem("rt", rt);
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("_Bearer");
    localStorage.removeItem("rt");
  }
};
