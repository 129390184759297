import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getPackages,getSubscriptions,} from "../../state/actions/cameras-actions";
import moment from "moment/moment";
import Button from "../../components/Button";
import ContentWrapper from "../../components/ContentWrapper";
import { useState } from "react";
import Modal from "../../components/Modal";
import NewPackage from "./NewPackage";
import { useLocation, useParams } from "react-router-dom";
import { calculateRemainingDays } from "../../utils/SubscriptionDateHelpers";
import LoadingDiv from "../../components/LoadingDiv";
import { activeTrailPackage, getTrialPackage } from "../../state/actions/trial-package-actions";
import { successAlert } from "../../state/actions/alert-actions";
const CameraDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let inventorySerial = queryParams.get('inventory-serial')
  const tabs = [
    {
      id:1,
      name:'Active',
      label:'active'
    },
    {
      id:2,
      name:"Pending",
      label:'pending'
    },
    {
      id:3,
      name:"History",
      label:'history'
    }
  ]
  const [activeTab,setActiveTab] = useState('Active') 
  const handleTab = (tabName) =>{
    setActiveTab(tabName)
  }


    const dispatch = useDispatch();
  const params = useParams();

  const { id: camera_id } = params;

  const { profileData, subscriptionData ,loginUser} = useSelector(
    (state) => ({
      profileData: state?.profile?.profileData,
      loading: state?.profile?.loaders?.profile,
      cameraData: state?.cameras?.cameraData,
      subscriptionData: state?.cameras?.subscriptionData,
      subscriptionDataLoader: state?.cameras?.loaders?.subscription,
      loginUser: state?.profile?.profileData?.subscriber_details

    })
  );
  const [trailPackage,setTrailPackage] = useState();
  const [newOpen, setNewOpen] = useState(false);
  const [loading,setLoading] = useState(true);
  const [renderPage,setRenderPage] = useState(false)

  useEffect(() => {
    if(loginUser?.subscriber_id && inventorySerial){
      dispatch(getTrialPackage({subscriberId:loginUser?.subscriber_id,inventorySerial:inventorySerial})).then((res)=>{
        
        setLoading(false)
      if(res === undefined || res?.status === 400 || res?.status === 204){
        setLoading(true)
        if (camera_id && newOpen) {
          dispatch(getPackages(camera_id)).then((res)=>{
            if(res?.status === 200){
              setLoading(false)
            }
          })
        }
        if (profileData?.id) {
          dispatch(getSubscriptions(profileData?.id, camera_id)).then((res)=>{
              setLoading(false)
              if(res?.status === 200){
                setLoading(false)
              }
            })
          }
      }else if(res.status === 200){
        setTrailPackage(res.data)
        setLoading(false)
      }else if(res.status === 422){
        setLoading(false)
        console.log("@error")
      }
    },(err)=>{
      setLoading(false)
      console.log("@errorTrailPackage",err)
    })}
  }, [profileData, camera_id,renderPage]);

  function expireCheckDate(dateStr) {
    const targetDate = new Date(dateStr);
    const currentDate = new Date();
    if (targetDate > currentDate) {
        return false;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
        return false;
    } else {
        return true;
    }
}
  function activeCheckDate(dateStr) {
    const targetDate = new Date(dateStr);
    const currentDate = new Date();
    if (targetDate > currentDate) {
        return true;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
        return true;
    } else {
        return false;
    }
}

const [activateLoader,setActivateLoader] = useState(false)
function activatePackage(packageId,planId){
  setActivateLoader(true)
  let data ={
    "package_id": packageId,
    "package_plan_id": planId,
    "payment_gateway": "cash",
    // "payment_method": "cash",
    "qty": 1,
    "subscriber_id": 15710,
    "created_by": `${profileData?.username}`,
    "subscriber_ipcam_id": 1 * camera_id
}
dispatch(activeTrailPackage(data)).then((res)=>{
  if(res.status === 200){
    dispatch(successAlert("Your trail package has been activated Successfully ."))
      setLoading(false)
      window.location.href = `/cameras/subscription/${camera_id}?inventory-serial=${inventorySerial}`
    setRenderPage(true)
  }
})
}

const activeTabCss = `text-[#1A374D] border-b-2 border-[#1A374D] rounded-t-lg active dark:text-[#1A374D] dark:border-[#1A374D]`;
const inActiveTabCss = `border-b-2 border-transparent rounded-t-lg hover:text-blue-600 hover:border-blue-500 dark:hover:text-blue-500`;

  return (
    <>{
      trailPackage?.plans?.length > 0 ? 
       loading ? <LoadingDiv/> : <ContentWrapper title="TRIAL PACKAGE">
          <div>
            <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
              <div className="flex justify-between">
                <p className="font-medium flex gap-x-2 items-start">
                  {trailPackage?.name}
                </p>
                <div>
                {activateLoader ? <LoadingDiv/> :<Button handleClick={() => activatePackage(trailPackage?.id,trailPackage?.plans[0]?.id)}>
                    Activate Package
                  </Button>}
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
       : 
      (loading ? <LoadingDiv/> : <ContentWrapper title={"SUBSCRIPTIONS"}>
        <div>
          <div className='flex justify-between items-center '>
            <div>
              <ul className="flex flex-wrap -mb-px">
                  {tabs.map((tab)=>{
                    return (
                      <li className="me-2">
                        <a href={`#${tab.label}`} className={`inline-block text-base font-medium mr-4 pr-2 pb-3 ${activeTab === tab.name ? activeTabCss : inActiveTabCss}`} onClick={() => handleTab(tab.name)}>{tab.name}</a>
                      </li>)
                  })}
              </ul>
            </div>
            <div>
              <Button handleClick={() => {
              setNewOpen(true);
            }}>
                Buy Package
              </Button>
            </div>
          </div>
          {
            activeTab === 'Active' && 
            <div className="mt-4">
              {subscriptionData?.packages?.length > 0 && subscriptionData?.packages?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date))))[0] !== undefined ?
              ( [subscriptionData?.packages?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date))))[0]].map((each)=>(
                  <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                      <div className="flex justify-between">
                        <p className="font-medium flex gap-x-2 items-start">
                          {each?.name}
                        </p>
                        <p>
                          <span className="text-gray-400 text-sm font-medium">
                            Remaining:
                          </span>
                          <span className="font-semibold">
                            {calculateRemainingDays(
                              each?.subscription_expiry_date
                            )}{" "}
                            Days{" "}
                          </span>
                        </p>
                      </div>
                      <div className=" flex justify-between ">
                        <p
                          title={`Start Date: ${moment(each?.start_date).format(
                            "LL"
                          )}`}
                          className=""
                        >
                          <span className="text-gray-400 text-sm font-medium">
                            Expires on:{" "}
                          </span>
                          <span className="font-semibold">
                            {moment(each?.subscription_expiry_date).format("LL")}
                          </span>
                        </p>
                      </div>
                    </div>
                ))) : (
                  <div className="my-8">
                    <p className="text-center font-medium">No Records Founds</p>
                  </div>
                )
                
              }
            </div> }
            {activeTab === 'Pending' && 
            <div>
              {subscriptionData?.packages?.length > 0 && ( subscriptionData?.packages
              ?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date)))))?.length > 1 ? (
              <div className="grid gap-y-4 mt-4">
                {subscriptionData?.packages
                  ?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date)) ))?.slice(1)
                  ?.map((each) => (
                    <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                      <div className="flex justify-between">
                        <p className="font-medium flex gap-x-2 items-start">
                          {each?.name}
                        </p>
                      </div>
                      <div className=" flex justify-between ">
                        <p
                          title={`Start Date: ${moment(each?.start_date).format(
                            "LL"
                          )}`}
                          className=""
                        >
                          <span className="text-gray-400 text-sm font-medium">
                            Expires on:{" "}
                          </span>
                          <span className="font-semibold">
                            {moment(each?.subscription_expiry_date).format("LL")}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              ): (
                <div className="my-8">
                    <p className="text-center font-medium">No Records Founds</p>
                  </div>
              )}
            </div>} 
          { activeTab === 'History' && 
            <div>
              {subscriptionData?.packages?.length > 0 &&
              subscriptionData?.packages?.filter(
                (each) => each?.subscription_status === 0
              ).length > 0 ? (
                <div className="my-4">
                  <div className="grid gap-y-4 mt-4  opacity-75">
                    {subscriptionData?.packages
                      ?.filter((each) => each?.subscription_status === 0 || expireCheckDate(each.subscription_expiry_date))
                      ?.map((each) => (
                        <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                          <div className="flex justify-between">
                            <p className="font-medium flex gap-x-2 items-start">
                              {each?.name}
                            </p>
                            <p></p>
                          </div>
                          <div className=" flex justify-between ">
                            <p>
                              <span className="text-red-400 text-base font-semibold uppercase">
                                Expired
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ): (
                  <div className="my-8">
                    <p className="text-center font-medium">No Records Founds</p>
                  </div>
                )}
            </div> }
        </div>
        <Modal
          open={newOpen}
          onClose={() => {
            setNewOpen(false);
          }}
          body={
            <NewPackage
              camera_id={camera_id}
              handleClose={() => {
                setNewOpen(false);
              }}
            />
          }
          className={"xs:w-full md:w-3/5"}
        />
      </ContentWrapper>)
    }</>
  )
}

export default CameraDetails