import { combineReducers } from "redux";
import { camerasReducer, loginReducer, profileReducer ,createSubscriberKyc,getSubscriberKyc,UpdateSubscriberKyc,getCountries} from "../reducers";

const reducers = combineReducers({
  login: loginReducer,
  profile: profileReducer,
  cameras: camerasReducer,
  createsubscriberkyc: createSubscriberKyc,
  getsubscriberkyc:getSubscriberKyc,
  updatesubscriberkyc:UpdateSubscriberKyc,
  getCountries
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

export default rootReducer;
