import { Link, useLocation } from "react-router-dom";
import useWindowDimesions from "../components/shared/Hooks/useWindowDimensions";
import DropdownMenu from "../components/DropdownMenu";
import { Menu } from "react-feather";

const HeaderLinks = () => {
  const location = useLocation();

  const Links = [
    { label: "Dashboard", route: "/" },
    { label: "Cameras", route: "/cameras" },
    // { label: "Area", route: "/my-home" },
  ];

  const { width } = useWindowDimesions();

  return width < 740 ? (
    <DropdownMenu
      iconContent={<Menu />}
      body={
        <div className="grid md:gap-y-6 xs:gap-y-3 text-sm ">
          {Links.map((each) => (
            <Link
              to={each.route}
              className={`${
                location.pathname === each.route ? "text-blue-700 " : ""
              }   hover:text-blue-500 uppercase font-medium border-b last:border-b-0 px-4`}
              key={each.route}
            >
              {each.label}
            </Link>
          ))}
        </div>
      }
    ></DropdownMenu>
  ) : (
    <div className="flex md:gap-x-4 xs:gap-x-3 text-sm">
      {Links.map((each) => (
        <Link
          to={each.route}
          className={`${
            location.pathname === each.route
              ? "bg-primary rounded-lg text-white "
              : "hover:text-blue-500"
          }   font-medium  py-2 px-4`}
          key={each.route}
        >
          {each.label}
        </Link>
      ))}
    </div>
  );
};

export default HeaderLinks;
