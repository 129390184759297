import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authRequest } from "../../state/actions/login-actions";
import WlinkBanner from "../../assets/image/wlinkbanner.jpg";
import { Input, PasswordInput } from "../../components/Inputs";
import { useNavigate } from "react-router-dom";

const WlinkLogin = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const { error, loading } = useSelector((state) => ({
    error: state.login?.error?.data,
    loading: state.login?.processing,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };

    if (username === "" || password === "") {
      toast.error("Please enter the required fields", { theme: "colored" });
    } else {
      dispatch(authRequest(data, navigate));
    }
  };

  return (
    <div className="md:min-w-[400px] xs:min-w-full">
      <img src={WlinkBanner} alt="logo" className="w-40 mx-auto !my-2" />
      <div className="w-full bg-white  md:mt-0 sm:max-w-md xl:p-0 mx-auto">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-[#1a374d] md:text-2xl">
            Sign in to your account
          </h1>
          <form onSubmit={onSubmit} className="space-y-4 md:space-y-6">
            <div>
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Username
              </label>
              <Input
                value={username}
                handleChange={(e) => {
                  setUsername(e.target.value);
                }}
                placeholder="username"
                error={error?.username?.[0]}
                autoComplete="on"
                data-testid="username"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Password
              </label>
              <PasswordInput
                value={password}
                handleChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={error?.password?.[0]?.password}
                placeholder="password"
              />
            </div>

            {typeof error !== "object" && (
              <small className="text-error mt-1 text-xs">{error}</small>
            )}

            <button
              type="button"
              onClick={(e) => onSubmit(e)}
              className="w-full text-white bg-blue-500 hover:bg-blue-600 hover:transition-all focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:bg-blue-700"
              data-testid="login"
            >
              {loading ? "Logging In..." : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WlinkLogin;
