export const subscriberKycConstants = {

    FETCH_SUBSCRIBER_SUCCESS: 'FETCH_SUBSCRIBER_SUCCESS',
    FETCH_SUBSCRIBER_ERROR: "FETCH_SUBSCRIBER_ERROR",
    FETCH_SUBSCRIBER_PROCESSING: "FETCH_SUBSCRIBER_PROCESSING",

    EDIT_SUBSCRIBERS_SUCCESS: 'EDIT_SUBSCRIBERS_SUCCESS',
    EDIT_SUBSCRIBERS_ERROR: 'EDIT_SUBSCRIBERS_ERROR',
    EDIT_SUBSCRIBERS_PROCESSING: 'EDIT_SUBSCRIBERS_PROCESSING',

    ADD_SUBSCRIBERS_SUCCESS: "ADD_SUBSCRIBERS_SUCCESS",
    ADD_SUBSCRIBERS_ERROR: "ADD_SUBSCRIBERS_ERROR",
    ADD_SUBSCRIBERS_PROCESSING: 'ADD_SUBSCRIBERS_PROCESSING',

    MAIN_TITLE_FOR_SUBSCRIBER_KYC: "Create Subscriber KYC",
    MAIN_TITLE_FOR_EDIT_SUBSCRIBER: "Subscriber KYC Detail",
    CARD_HEADER_FOR_SUBSCRIBER: "Subscriber KYV Verification",

    SUBSCRIBER_KYC_API: "/subscriber/kyc/v1/subscribers",
 

};