import React, { memo } from "react";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";

import "./modal.css";
import { X } from "react-feather";

const ModalContainer = memo(({ children }) => {
  const domEl = document.getElementById("modal-root");

  if (!domEl) return null;

  // This is where our modal div will be rendered into our 'modal-root' div, no matter where we
  // use this component inside our React tree
  return ReactDOM.createPortal(<div>{children}</div>, domEl);
});

/**
 *
 * Dialog
 *
 */

function useComponentVisible(initialIsVisible, setShowList) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    // uses ref to check if outside of Div is clicked
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      if (setShowList === undefined) {
        console.log("!! onClose function not passed to dialog component. !!");
      } else {
        setShowList(false);
      }
    }
  };

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowList(false);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const Modal = ({
  open,
  onClose,
  className,
  title,
  body,
  actions,
  fullScreen,
  disableOutside,
  fullBody,
  titleFields,
}) => {
  useComponentVisible(open, onClose);

  /**
   *
   * @param  {boolean} open // pass true to show modal
   * @param  {Function} onClose // handle closing of modal
   * @param  {String} title // shows title of modal
   * @param  {ReactDOM} body // render main content of modal here
   * @param  {ReactDOM} actions // renders at bottom of modal
   * @param  {boolean} fullScreen // make modal cover whole screen
   *
   */

  const dialogHeight = { maxHeight: "calc(100vh - 200px)" };
  const fullHeight = { height: "calc(100vh - 24px)" };

  const children = (
    <>
      <div
        className={`w-screen ${
          fullBody ? "h-[100vh-200px]" : "h-screen"
        }  z-50 fixed top-0 left-0 bg-black bg-opacity-50 overflow-auto`}
        onClick={disableOutside === true ? "" : onClose}
      />
      <div
        className={`fixed z-50 shadow-2xl bg-white  
        ${
          fullScreen === true
            ? "top-10 right-10 left-10 rounded-3xl"
            : "left-1/2 top-1/4 transform -translate-x-1/2 -translate-y-1/4 rounded-2xl min-w-2xl slide-dialog"
        }
        ${className && className !== "" ? className : ""} `}
      >
        {title !== undefined && (
          <div className="flex flex-wrap items-center justify-between px-6 py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <h3 className="m-0 text-lg text-black pr-20 capitalize">{title}</h3>
            <div className="flex gap-4">
              {titleFields}
              <button
                type="button"
                className="text-gray-700 hover:text-gray-500 text-xl font-semibold"
                data-testid="close-modal"
                onClick={onClose}
              >
                <X className="stroke-[3px]" />
              </button>
            </div>
          </div>
        )}
        {body !== undefined && (
          <div
            style={fullBody === true ? fullHeight : dialogHeight}
            className={fullBody === true ? "p-2" : "overflow-auto"}
          >
            {body}
          </div>
        )}
        {actions !== undefined && (
          <div className="border-t p-2 flex justify-end">{actions}</div>
        )}
      </div>
    </>
  );

  return open ? <ModalContainer>{children}</ModalContainer> : null;
};

export default Modal;
