import React, {Component} from 'react';

class InputBasic extends Component {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        type: "text"
    }

    onTogglePassword(e) {
        var classList = e.target.classList
        let flag = false
        for (var i = classList.length >>> 0; i--;) {
            if (classList[i] === "fa-eye-slash")
                flag = true
        }
        if (flag) {
            classList.remove("fa-eye-slash")
            classList.add("fa-eye")
            e.target.parentNode.childNodes.item(1).setAttribute("type", "password")
        } else {
            classList.remove("fa-eye")
            classList.add("fa-eye-slash")
            e.target.parentNode.childNodes.item(1).setAttribute("type", "text")
        }
    }


    displayError(error) {
        if (error) {
            return (

                    <div className="invalid-feedback position-absolute text-sm text-red-500">{error[0]}</div>

            )
        } else {
            return (<></>)
        }
    }

    render() {
        let {label, name, onChange, error, value, disabled, readOnly, placeholder, type, min, password, id, alignLeft, helpTip, autoComplete, onFocus,helpAlignment} = this.props;
        if (type === "file") value = null
        else {
            value = value ? value : ""
        }
        return (
            <div class={" col-12"}>
                <div class={"d-flex align-items-center justify-content-between"}>
                    {
                        label &&
                        <label htmlFor="validationServer03" className="form-label text-sm text-gray-500">{label}

                        </label>
                    }
                    {helpTip && helpTip
                        ?
                        <React.Fragment>
                        <span className="toolTipForm" data-bs-toggle="tooltip" data-bs-placement={helpAlignment} title={helpTip}>
                                        <i className="ri-question-fill" style={{ color: "#d4d4d4" }}></i>
                                    </span>
                        </React.Fragment>
                        :
                        null
                    }

                </div>
                <input className={error ? "form-control is-invalid inputbox" : `text-sm  ${disabled ? 'text-gray-500' :''} rounded-md w-full` }
                       id={id}
                       type={password ? "password" : type ? type : "text"}
                       placeholder={placeholder}
                       name={name}
                       value={value}
                       onChange={e => onChange(e, name)}
                       min={min}
                       onFocus={onFocus}
                       autoComplete={autoComplete}
                       readOnly={readOnly}
                       disabled={disabled}/>



                {(type && type === "password" && id && id === "password") &&
                    <div className="row">
                        <div className="col-xs-12">
                            {/*<span onKeyUp={this.handleKeyUp}></span>*/}
                            {
                                value ?
                                    <progress id="progress" value="0" max="100"></progress>
                                    :
                                    <progress id="progress" value="0" max="100" style={{display: "none"}}></progress>
                            }
                            &nbsp;
                            {
                                value ?
                                    <small id="msg"></small>
                                    :
                                    <small id="msg" style={{display: "none"}}></small>
                            }
                        </div>
                    </div>
                }

                {this.displayError(error)}
            </div>
        )
    }
}

export default InputBasic
