import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Input } from "../../components/Inputs";
import { forgetPassword } from "../../state/actions/login-actions";
import AuthWrapper from "./AuthWrapper";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, loading } = useSelector((state) => ({
    error: state.login?.error?.data,
    loading: state.login?.processing,
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      // code: companyCode,
    };

    if (email === "") {
      toast.error("Please enter the email.", { theme: "colored" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      toast.error("Please enter valid email.", { theme: "colored" });
    } else {
      localStorage.setItem('email',email) 
      dispatch(forgetPassword(data, navigate));
    }
  };

  return (
    <AuthWrapper>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-[#009581] md:text-2xl">
          Forgot your password?
        </h1>
        <p className="text-sm !mt-2 text-gray-400">
          Just fill in your email and we’ll help you reset your password.
        </p>
        <form
          className="space-y-4 md:space-y-6"
          type="submit"
          onSubmit={onSubmit.bind(this)}
        >
          {/* <Input
            value={companyCode}
            handleChange={(e) => setCompanyCode(e.target.value)}
            label={"Code"}
            placeholder={"Enter company code"}
            error={error?.code?.[0]}
          /> */}
          <div>
            <label
              htmlFor="username"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email Address
            </label>
            <Input
              value={email}
              handleChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              error={error?.email?.[0]}
              data-testid="email"
            />
          </div>

          <button
            type="button"
            data-testid="forgot"
            onClick={onSubmit.bind(this)}
            className="w-full text-white bg-blue-500 hover:bg-blue-600 hover:transition-all focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
          <button className="mx-auto w-full !mt-3">
            <Link
              className="text-sm font-medium text-blue-600 hover:underline"
              to="/login"
            >
              Back to Login?
            </Link>
          </button>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
