import jwtDecode from "jwt-decode";
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserMenu from "../components/UserMenu";
import {
  getProfileData,
  loadAllNotifications,
  setNewNotification,
} from "../state/actions/profile-actions";
import BrandLogo from "../assets/image/brandlogo.png";
import HeaderLinks from "./HeaderLinks";

function Header({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const { serverPage, new_notification, profileData } = useSelector(
    (state) => ({
      serverPage: state.profile?.serverError,
      new_notification: state.profile?.new_notification,
      profileData: state.profile?.profileData,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (serverPage === true) {
      navigate("/error-page");
    }
  }, [serverPage]);

  const parseJwt = (token) => {
    try {
      return jwtDecode(token);
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("_Bearer");
    if (token) {
      setTimeout(() => dispatch(getProfileData()), 1);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("_Bearer");
    if (
      token &&
      (window?.location?.pathname.includes("forgot") ||
        window?.location?.pathname.includes("registration"))
    ) {
      localStorage.removeItem("_Bearer");
      localStorage.removeItem("rt");
    }
    if (
      !token &&
      !window?.location?.pathname.includes("forgot") &&
      !window?.location?.pathname.includes("registration")
    ) {
      navigate("/login");
    }

    if (
      token &&
      (window?.location?.pathname.includes("login") ||
        window?.location?.pathname.includes("registration") ||
        window?.location?.pathname.includes("forgot"))
    ) {
      navigate("/");
    }
  }, [window?.location?.pathname]);

  const [notificationOpen, setNotificationOpen] = useState(false);

  return (
    <header
      className={`sticky top-0 bg-white py-1 backdrop-blur-3xl border-b border-slate-200 lg:px-28 xs:px-1 shadow-lg  z-30 ${
        pathname.includes("login") ||
        pathname.includes("forgot") ||
        pathname.includes("registration")
          ? "hidden"
          : ""
      }`}
      data-testid="main-header"
    >
      <div className="px-4 ">
        <div className="flex items-center justify-between h-16 -mb-px ">
          {/* Header: Left side */}
          <div className="flex gap-4">
            {/* Hamburger button */}
            <NavLink end to="/" className="flex justify-center">
              <img src={BrandLogo} className="w-full max-h-10" />
            </NavLink>
          </div>

          <HeaderLinks />


          {/* Header: Right side */}
          <div className="flex gap-x-4 items-center">
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
