import { Link } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";

const ForgotConfirmation = () => {
  return (
    <AuthWrapper>
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-[#009581] md:text-2xl">
                Email sent successfully
            </h1>
            <p className="text-sm !mt-2 text-gray-400">
                An email has been sent to the address you provided with instructions
                on how to reset your password.
            </p>
            <Link to="/forgot-password">
                <button className="w-full text-white bg-blue-500 hover:bg-blue-600 hover:transition-all focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-6">
                Resend Email
                </button>
            </Link>
            <button className="mx-auto w-full !mt-3">
                <Link
                className="text-sm font-medium text-blue-600 hover:underline"
                to="/registration"
                >
                Back to Login?
                </Link>
            </button>
            </div>
    </AuthWrapper>
  );
};

export default ForgotConfirmation;
