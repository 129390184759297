import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const navigate = useNavigate();
  const inactivityTimerRef = useRef(null);

  const startInactivityTimer = () => {
    clearTimeout(inactivityTimerRef.current);

    const timer = setTimeout(logOutHandler, 7200000);
    inactivityTimerRef.current = timer;
  };

  const resetInactivityTimer = () => {
    startInactivityTimer();
  };

  const logOutHandler = () => {
    sessionStorage.setItem("redirect", window.location.pathname);
    localStorage.removeItem("_Bearer");
    localStorage.removeItem("rt");
    localStorage.removeItem("tenant_id");
    localStorage.removeItem("role");
    navigate("/login");
  };

  useEffect(() => {
    const resetTimerOnActivity = () => {
      resetInactivityTimer();
    };

    document.addEventListener("keydown", resetTimerOnActivity);
    document.addEventListener("mousemove", resetTimerOnActivity);
    document.addEventListener("click", resetTimerOnActivity);
    document.addEventListener("scroll", resetTimerOnActivity);
    startInactivityTimer();

    return () => {
      document.removeEventListener("keydown", resetTimerOnActivity);
      document.removeEventListener("mousemove", resetTimerOnActivity);
      document.removeEventListener("click", resetTimerOnActivity);
      document.removeEventListener("scroll", resetTimerOnActivity);
      clearTimeout(inactivityTimerRef.current);
    };
  }, []);

  return null;
};

export default Navigation;