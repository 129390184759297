import React, { useEffect, useState } from "react";
import { Info } from "react-feather";



const Alerts = (props) => {

  /**
   *
   * @param  {String} direction // values = top / bottom / left / right
   *
   */
  let [show,setShow] = useState(true);
  
  useEffect(()=>{
    if(props.kyc_verified !==0){
      setShow(true)
    }
  },[props.kyc_verified])

  return (
    <>
{
  show && 
  <div class="space-y-5 w-80 mb-2 mt-0 text-start">
  <div class=" flex justify-between p-1 shadow rounded-lg line-height-10"
  style={{ background:props?.kyc_verified === 0?"#E08A0C" :
  props?.kyc_verified === null?"#D83A08":
  props?.kyc_verified === 1?"#4CAF50":"#c5c0c0ed"
  
  }}
  >
    <a href="/profile/kyc">
    <p class="font-sans pt-1 pl-3" >
    {props?.message}
    </p>
    { 
      props?.kyc_verified === 0 &&
      <p class="pt-1 pl-3  text-xs" >
      {props?.reason   && props?.reason.charAt(0).toUpperCase() + props?.reason.slice(1)}
      </p>
    }
    </a>
    {/* <strong className="text-xl lighn-center cursor-pointer alert-del pr-3" onClick={(e)=> setShow(false)}>&times;</strong> */}

  </div>
  </div>
}


    </>
  );
};

export default Alerts;
