import { API_URL } from "../../constants";

const TENANT_ID = "2"; // for login set from api :TODO

export const loginConstants = {
  /* Misc */
  API: `${API_URL}/tenant/authentication/v1/tenants/${TENANT_ID}/access-token`,
  // FORGET_API: `${API_URL}/public/account/v1/users/forgot-password`,
  FORGET_API: `${API_URL}//public/register/v1/subscribers/reset-password-request`,
  RESET_API: `${API_URL}/public/account/v1/users/change-password`,
  REGISTRATION_API: `${API_URL}/public/account/v1/users/registration-change-password`,

  /* Reducers */
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_ERROR: "LOGIN_REQUEST_ERROR",

  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_REQUEST_SUCCESS: "FORGET_PASSWORD_REQUEST_SUCCESS",
  FORGET_PASSWORD_REQUEST_ERROR: "FORGET_PASSWORD_REQUEST_ERROR",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_REQUEST_SUCCESS: "RESET_PASSWORD_REQUEST_SUCCESS",
  RESET_PASSWORD_REQUEST_ERROR: "RESET_PASSWORD_REQUEST_ERROR",

  SOCIAL_LOGIN_PROCESSING: "SOCIAL_LOGIN_PROCESSING",
  SOCIAL_LOGIN_SUCCESS: "SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_ERROR: "SOCIAL_LOGIN_ERROR",

  WLINK_LOGIN_PROCESSING: "WLINK_LOGIN_PROCESSING",
  WLINK_LOGIN_SUCCESS: "WLINK_LOGIN_SUCCESS",
  WLINK_LOGIN_ERROR: "WLINK_LOGIN_ERROR",

  SET_SENT: "SET_SENT",
  CLEAR_ALL: "CLEAR_ALL",
};
