import { processing, success, failure } from "./axios";

import axios from "axios";
import { Config } from "../../Config";
import { loginToken } from "../../helpers/GeneralHelpers";

export function getCountries()
{
    return dispatch => {
        dispatch(processing('FETCH_COUNTRIES_PROCESSING',true));
        let config = {
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }

        };

        return axios.get(`https://resources.geniustv.dev.geniussystems.com.np/countries`, config)
            .then(res => {
                dispatch(processing('FETCH_COUNTRIES_PROCESSING',false));
                dispatch(success('FETCH_COUNTRIES_SUCCESS', res));
                return res;
            }).catch(error => {
                dispatch(failure('FETCH_COUNTRIES_ERROR',error));
                dispatch(processing('FETCH_COUNTRIES_PROCESSING',false));
                return error
            });
    }
}

export default getCountries;