import { memo } from "react";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import "./modal.css";
import { X } from "react-feather";

const ModalContainer = memo(({ children }) => {
  const domEl = document.getElementById("modal-root");

  if (!domEl) return null;

  // This is where our modal div will be rendered into our 'modal-root' div, no matter where we
  // use this component inside our React tree
  return ReactDOM.createPortal(<div>{children}</div>, domEl);
});

/**
 *
 * Dialog
 *
 */

function useComponentVisible(initialIsVisible, setShowList) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    // uses ref to check if outside of Div is clicked
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      if (setShowList === undefined) {
        console.log("!! onClose function not passed to dialog component. !!");
      } else {
        setShowList(false);
      }
    }
  };

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowList(false);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const Drawer = ({
  open,
  onClose,
  className,
  title,
  body,
  actions,
  size,
  footerButton,
}) => {
  useComponentVisible(open, onClose);

  /**
   *
   * @param  {boolean} open // pass true to show modal
   * @param  {Function} onClose // handle closing of modal
   * @param  {String} title // shows title of modal
   * @param  {ReactDOM} body // render main content of modal here
   * @param  {ReactDOM} actions // renders at bottom of modal
   * @param  {boolean} fullScreen // make modal cover whole screen
   *
   */

  const dialogHeight = { maxHeight: "calc(95vh - 20px)", minHeight: "h-full" };

  const sizeVals = {
    small: "xs:w-full  md:w-1/3",
    medium: "xs:w-full  md:w-3/5",
    large: "xs:w-full  md:w-4/5 lg:w-3/5",
    x_large: "xs:w-full  md:w-4/5",
    full: "w-full",
  };

  const children = (
    <>
      <div
        className="w-screen h-screen z-50 fixed top-0 left-0 bg-black bg-opacity-10 overflow-auto"
        onClick={onClose}
      />
      <div
        className={`fixed z-50 shadow-2xl bg-gray-50  right-0 top-0 ${
          sizeVals[size || "medium"]
        } h-full slide-drawer
        ${className && className !== "" ? className : ""} `}
      >
        {title !== undefined && (
          <div className="flex flex-wrap items-center justify-between px-6 py-3 h-16 bg-gray-200 rounded-tl-lg rounded-tr-lg">
            <h3 className="text-xl leading-3 pr-20">{title || "Drawer"}</h3>
            <button
              type="button"
              className="text-gray-600 hover:scale-105 transition-all text-xl"
              onClick={onClose}
            >
              <X />
            </button>
          </div>
        )}
        {body !== undefined && (
          <div style={dialogHeight} className="p-4 overflow-y-auto bg-gray-50 ">
            {body}
          </div>
        )}
        <div
          className={` fixed bottom-0 left-auto right-0 z-[9999] transition-all ${
            footerButton === undefined ? "h-0" : "h-11 py-1.5 mb-2 px-4 "
          }`}
        >
          {footerButton}
        </div>
        {actions !== undefined && (
          <div className="border-t p-2 flex justify-end">{actions}</div>
        )}
      </div>
    </>
  );

  return open ? <ModalContainer>{children}</ModalContainer> : null;
};

export default Drawer;
