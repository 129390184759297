import React from "react";
import { Info } from "react-feather";
import "./input.css";

const Tooltip = ({ direction, children, className, icon, boxCss, hide }) => {
  /**
   *
   * @param  {String} direction // values = top / bottom / left / right
   *
   */
  return (
    <>
      <div
        className={`tooltip ${className && className !== "" ? className : ""}`}
        role="definition"
      >
        {icon ? (
          icon
        ) : (
          <Info
            size={15}
            strokeWidth={3}
            className="text-gray-400 hover:text-gray-600 inline-block cursor-pointer"
          />
        )}
        {children && (
          <div
            className={`tooltipBox text-xs bg-black text-white   ${
              boxCss ? boxCss : "w-40"
            } ${direction && direction !== "" ? direction : "top"} ${
              hide === true ? "hidden" : ""
            } `}
            data-testid="modal"
          >
            <p className="tooltipText">{children}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Tooltip;
