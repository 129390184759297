import React from "react";

const Button = ({
  variant,
  icon,
  isDisabled,
  type,
  handleClick,
  children,
  shape,
  appendIcon,
  extraClasses,
  testid,
  ...restProps
}) => {
  /**
   *
   * @param  {String} variant // values = default / success / error / warning
   * @param  {String} shape // values = default / sharp / pill
   * @param  {ReactDOM} icon // show icon before text
   * @param  {String} extraClasses // add new classes if required
   *
   */

  const variantVals = {
    default:
      "bg-primary hover:bg-blue-600 text-white hover:transition-all focus:outline-none focus:ring-blue-300 focus:bg-blue-800",
    success:
      "bg-success hover:bg-green-600 text-white hover:transition-all focus:outline-none focus:ring-green-300 focus:bg-green-800",
    error:
      "bg-gray-200 hover:bg-gray-300 !text-gray-700 hover:transition-all focus:outline-none focus:ring-gray-300 focus:bg-gray-400",
    warning:
      "bg-warning hover:bg-yellow-600 text-white hover:transition-all focus:outline-none focus:ring-yellow-300 focus:bg-yellow-800",
    cancel:
      "bg-danger hover:bg-red-600 text-white hover:transition-all focus:outline-none focus:ring-red-300 focus:bg-red-800",
  };

  const shapeVals = {
    default: "rounded-md",
    sharp: "",
    pill: "rounded-full",
  };
  return (
    <button
      type={type || "button"}
      className={`text-white  text-sm font-medium py-1.5 px-3 disabled:cursor-not-allowed flex flex-wrap justify-center content-center ${
        variantVals[variant || "default"]
      }  ${shapeVals[shape || "default"]}
      ${extraClasses ? extraClasses : ""}
      `}
      onClick={handleClick}
      disabled={isDisabled}
      data-testid={testid || "button"}
      {...restProps}
    >
      {!appendIcon && icon !== undefined ? (
        <span className="mt-1 mr-2">{icon}</span>
      ) : (
        ""
      )}
      {children}
      {appendIcon && icon !== undefined ? (
        <span className="mt-1 ml-2">{icon} </span>
      ) : (
        ""
      )}
    </button>
  );
};

export default Button;
