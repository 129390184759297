import { processing, success, failure } from "./axios";
import { toast } from "react-toastify";
import axios from "axios";
import { subscriberKycConstants } from '../constants';
import { Config } from '../../Config';
import { loginToken } from "../../helpers/GeneralHelpers";
export function createSubscribersKyc(data) {
    // return dispatch => {
    //     dispatch(processing(subscriberKycConstants.ADD_SUBSCRIBERS_PROCESSING,true));
    // fetch(`${Config.BaseUrl}/subscriber/kyc/v1/subscribers/store`, {
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json, text/plain, */*',
    //         'Content-Type': 'application/json',
    //         "Authorization": "Bearer " + loginToken()
    //         },
    //     body: JSON.stringify(data)
    // })
    // .then(function (response) {
    //     return response.json();
    // })
    // .then(function (result) {
    //     dispatch(success(subscriberKycConstants.ADD_SUBSCRIBERS_SUCCESS,result));
    //     return result
    // })
    // .catch (function (error) {
    //     dispatch(failure(subscriberKycConstants.ADD_SUBSCRIBERS_ERROR,false));
    //     return error
    // });
    // }

    return dispatch => {
        dispatch(processing(subscriberKycConstants.ADD_SUBSCRIBERS_PROCESSING,true));
        let config = {
            url: Config.BaseUrl + `/subscriber/kyc/v1/subscribers/store`, 
            method: "post",
            dataType: 'json',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        };

            return axios(config)
                .then(res => {
                    dispatch(success(subscriberKycConstants.ADD_SUBSCRIBERS_SUCCESS,res));
                    return res

                },error => {
                    if(error?.response){
                        dispatch(failure(subscriberKycConstants.ADD_SUBSCRIBERS_ERROR,error));
                        return error?.response
                    }
                }
                )
    }
}


export function getSubscriberKyc(id) {
    return dispatch => {

        dispatch(processing(subscriberKycConstants.ADD_SUBSCRIBERS_PROCESSING,true));
             return fetch(`${Config.BaseUrl}/subscriber/kyc/v1/subscribers/${id}/show`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + loginToken()
                    }
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (result) {
                dispatch(processing(subscriberKycConstants.FETCH_SUBSCRIBER_PROCESSING,false));
                dispatch(success(subscriberKycConstants.FETCH_SUBSCRIBER_SUCCESS,result));
                return result
            })
            .catch (function (error) {
                    dispatch(failure(subscriberKycConstants.FETCH_SUBSCRIBER_ERROR,error));
                    dispatch(processing(subscriberKycConstants.FETCH_SUBSCRIBER_PROCESSING,false));
                    return error
            });

        }
}


export function EditSubscribersKyc(id,data) {
    return dispatch => {
        dispatch(processing(subscriberKycConstants.EDIT_SUBSCRIBERS_PROCESSING,true));
        let config = {
            url: Config.BaseUrl + `${subscriberKycConstants.SUBSCRIBER_KYC_API}`+`/kycs/${id}/update`, 
            method: "patch",
            dataType: 'json',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        };

            return axios(config)
                .then(res => {
                    dispatch(success(subscriberKycConstants.EDIT_SUBSCRIBERS_SUCCESS,res));
                    return res

                },error => {
                    if(error?.response){
                        dispatch(failure(subscriberKycConstants.EDIT_SUBSCRIBERS_ERROR,error));
                        return error?.response
                    }
                }
                )
    }
}