export function PermissionsHelper(value, permission, id) {
  let val = [];
  let tempPermissions = "";

  val = permission
    ?.filter(
      (each) =>
        each?.slug === value + "-" + id ||
        ((value?.includes("edit") || value?.includes("add")) &&
          value?.split("/")?.[0] + "-" + id === each?.slug) ||
        value?.includes("faq") ||
        value?.includes("stb") ||
        value?.includes("ticket") ||
        value?.includes("profile") ||
        value?.includes("permissions/test") ||
        value?.includes("privacy-policy") ||
        value?.includes("addons") ||
        value?.includes("products") ||
        value?.includes("error") ||
        value?.includes("orders") ||
        value?.includes("notifications") ||
        value?.includes("inventory") ||
        value?.includes("miscellaneous")
    )
    ?.map((each) => each.slug);
  tempPermissions = val?.join(",");

  return !!tempPermissions;
  // return true;
}
