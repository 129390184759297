import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, AUTH_URL,SOCIAL_LOGIN } from "../../constants";
import { loginConstants } from "../constants";
import { processing, success, failure, setAuthToken } from "./axios";

const { API, FORGET_API } = loginConstants;

export const authRequest = (data, navigate) => {
  let url = `${AUTH_URL}/subscribers/authentication/v1/access-token`;

  return (dispatch) => {
    dispatch(processing(loginConstants.LOGIN_REQUEST, true));
    return axios.post(url, data).then(
      (response) => {
        navigate(sessionStorage.getItem("redirect") || "/");

        sessionStorage.removeItem("redirect");
        dispatch(success(loginConstants.LOGIN_REQUEST_SUCCESS, response));

        setAuthToken(response.data.access_token, response.data.refresh_token);
        return response;
      },
      (error) => {
        dispatch(failure(loginConstants.LOGIN_REQUEST_ERROR, error));
        return error;
      }
    );
  };
};

export const socialLogin = (data, navigate) => {
  let url = `${AUTH_URL}/third-party/social-login/v1/subscribers`;

  return (dispatch) => {
    dispatch(processing(loginConstants.SOCIAL_LOGIN_PROCESSING, true));
    return axios.post(url, { ...data, Platform: "okhttp" }).then(
      (response) => {
        navigate(sessionStorage.getItem("redirect") || "/");
        sessionStorage.removeItem("redirect");
        dispatch(success(loginConstants.SOCIAL_LOGIN_SUCCESS, response));
        setAuthToken(response.data.access_token, response.data.refresh_token);
        return response;
      },
      (error) => {
        dispatch(failure(loginConstants.SOCIAL_LOGIN_ERROR, error));
        return error;
      }
    );
  };
};

export const wlinkLogin = (data, navigate) => {
  let url = `${AUTH_URL}/third-party/social-login/v1/subscribers`;

  return (dispatch) => {
    dispatch(processing(loginConstants.WLINK_LOGIN_PROCESSING, true));
    return axios.post(url, data).then(
      (response) => {
        navigate(sessionStorage.getItem("redirect") || "/");
        sessionStorage.removeItem("redirect");
        dispatch(success(loginConstants.WLINK_LOGIN_SUCCESS, response));
        setAuthToken(response.data.access_token, response.data.refresh_token);
        return response;
      },
      (error) => {
        dispatch(failure(loginConstants.WLINK_LOGIN_ERROR, error));
        return error;
      }
    );
  };
};

export const loginRequest = (data, navigate, code) => {
  let code_url = `${API_URL}/tenant/account/v1/tenants/${code}`;
  return (dispatch) => {
    axios
      .get(code_url)
      .then((res) => {
        localStorage.setItem("tenant_id", res?.data?.id);
        let url = `${API_URL}/tenant/authentication/v1/tenants/${res?.data?.id}/access-token`;
        dispatch(authRequest(data, navigate, code, url));
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Code Invalid", { theme: "colored" });
      });
  };
};

export const forgetPassword = (data, navigate) => {
  let url = FORGET_API;
  return async (dispatch) => {
    dispatch(processing(loginConstants.FORGET_PASSWORD_REQUEST, true));
    return axios.post(url, data).then(
      (response) => {
        if (response.status == 204) {
          dispatch(failure(loginConstants.FORGET_PASSWORD_REQUEST_ERROR, ""));
          toast.error("User is not valid", { theme: "colored" });
          return response;
        } else {
          dispatch(
            success(loginConstants.FORGET_PASSWORD_REQUEST_SUCCESS, response)
          );
          toast.success(response.data.message, { theme: "colored" });
          navigate("/forgot-success");
          return response;
        }
      },
      (error) => {
        dispatch(failure(loginConstants.FORGET_PASSWORD_REQUEST_ERROR, error));
        toast.error(error.data.message, { theme: "colored" });
        return error;
      }
    );
  };
};

// export const resetPassword = (url, data, navigate, companyCode) => {
//   let code_url = `${API_URL}/tenant/account/v1/tenants/${companyCode}`;

//   return async (dispatch) => {
//     dispatch(processing(loginConstants.RESET_PASSWORD_REQUEST, true));
//     const tenant_id = await axios.get(code_url);

//     let saveData = { ...data, tenant_id: tenant_id?.data?.id };
//     if (tenant_id?.data?.id) {
//       return axios.post(url, saveData).then(
//         (response) => {
//           dispatch(
//             success(loginConstants.RESET_PASSWORD_REQUEST_SUCCESS, response)
//           );
//           toast.success(response.data.message, { theme: "colored" });
//           navigate("/login");
//           return response;
//         },
//         (error) => {
//           dispatch(failure(loginConstants.RESET_PASSWORD_REQUEST_ERROR, error));
//           toast.error(
//             error.data.message?.token
//               ? error.data.message?.token?.[0]
//               : error.data.message,
//             { theme: "colored" }
//           );
//           return error;
//         }
//       );
//     } else {
//       toast.error("Company Code is invalid.", { theme: "colored" });
//       dispatch(failure(loginConstants.RESET_PASSWORD_REQUEST_ERROR, ""));
//     }
//   };
// };


export const resetPassword = (data, navigate, companyCode) => {
  // let code_url = `${API_URL}/tenant/account/v1/tenants/${companyCode}`;
  let baseurl = `${API_URL}/public/register/v1/subscribers/reset-password`
  return async (dispatch) => {
    dispatch(processing(loginConstants.RESET_PASSWORD_REQUEST, true));
    // const tenant_id = await axios.get(code_url);
    // let saveData = { ...data, tenant_id: tenant_id?.data?.id };
    let saveData = {email:data.email, password:data.password,conf_password:data.password_confirm, otp:companyCode };
    // if (tenant_id?.data?.id) {
      return axios.post(baseurl, saveData).then(
        (response) => {
          dispatch(
            success(loginConstants.RESET_PASSWORD_REQUEST_SUCCESS, response)
          );
          toast.success(response.data.message, { theme: "colored" });
          navigate("/login");
          return response;
        },
        (error) => {
          dispatch(failure(loginConstants.RESET_PASSWORD_REQUEST_ERROR, error));
          dispatch(failure(loginConstants.RESET_PASSWORD_REQUEST_ERROR, ""));
          toast.error(
            error.data.message?.token
              ? error.data.message?.token?.[0]
              : error.data.message,
            { theme: "colored" }
          );
          return error;
        }
      );
    // } else {
    //   toast.error("Company Code is invalid.", { theme: "colored" });
    //   dispatch(failure(loginConstants.RESET_PASSWORD_REQUEST_ERROR, ""));
    // }
  };
};

export const clearAll = (payload) => ({
  type: loginConstants.CLEAR_ALL,
  payload: payload,
});


export const socialLoginList = () => {
  let code_url = `${AUTH_URL}/${SOCIAL_LOGIN}`;
  return (dispatch) => {
    return axios
      .get(code_url)
      .then((res) => {
        return res
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong", { theme: "colored" });
        return err
      });
  };
};
