import { ArrowLeft } from "react-feather";

const NotFoundPage = () => {
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <main data-testid="not-found-page" className="h-full">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full">
        <div className="relative bg-indigo-200 with-backdrop p-4 sm:p-6 overflow-hidden mb-8 rounded-xl">
          {/* Content */}
          <div className="relative">
            <h1 className="text-2xl md:text-3xl text-slate-800  font-bold mb-1">
              This page does not exist.
            </h1>
            <p
              className="flex items-end text-primary cursor-pointer font-bold mt-8"
              onClick={handleGoBack}
              data-testid="back-button"
            >
              <ArrowLeft size={15} className="mb-1" strokeWidth={4} /> Go Back
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
