import { useEffect, useState } from "react"

const useWindowDimesions = () => {

    const [size, setSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        setSize({
            ...size,
            width: window.innerWidth,
            height: window.innerHeight
        })
        const changeSize = () => {
            setSize({
                ...size,
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize', changeSize)
        return () => window.removeEventListener('resize', changeSize)
    }, [])

    return size
}

export default useWindowDimesions;