import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingDiv from "../../components/LoadingDiv";
import { useNavigate } from "react-router-dom";
import { Config } from "../../Config";

import { socialLogin } from "../../state/actions/login-actions";

const WRedirect = () => {
 
  const [processing,setProcessing] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch();
useEffect(()=>{
  const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if(code !== null){
  let datas = {
    login_type:"mywlink",
    token:code,
}
      setProcessing(!processing)
      // let redirect_url =`${Config.AuthUrl}/third-party/worldlink-login/v1/worldlink/login`;
      // axios.get(redirect_url,{datas}).then((res)=>{
      //   console.log("Res ",res);
      //   if(res?.status === 200){
          
          //login success and redirect to dashboard
          dispatch(socialLogin(datas, navigate));
        // navigate(sessionStorage.getItem("redirect") || "/");
        // sessionStorage.removeItem("redirect");
        // dispatch(success(loginConstants.SOCIAL_LOGIN_SUCCESS, res?.data?.message));
        // setAuthToken(data.token,data?.rt);
        // }else{
        //     //login fiald and redirect to login
        //     toast.error("Please enter the required fields", { theme: "colored" });
        //   }

    // })
    }
},[])

  return (<>
  <div className="flex h-screen items-center justify-center  athorizing">
      {processing ?<span>Authorizing ... <LoadingDiv/> </span>: "Authorizing ..."}
</div>
  </>);
};

export default WRedirect;
