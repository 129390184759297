import { useDispatch, useSelector } from "react-redux";
import LoadingDiv from "../../components/LoadingDiv";
import { getCameras } from "../../state/actions/cameras-actions";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { Link } from "react-router-dom";
import { Circle } from "react-feather";

const MyHome = () => {
  const { profileData, loading, cameraData } = useSelector((state) => ({
    profileData: state?.profile?.profileData,
    loading: state?.cameras?.loaders?.cameras,
    cameraData: state?.cameras?.cameraData,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCameras({id:profileData?.id}));
  }, []);

  const rooms = ["Bedroom", "Living Room", "Kitchen Room"];

  const [selectedRoom, setSelectedRoom] = useState("Bedroom");

  return (
    <ContentWrapper>
      <div className="flex flex-row w-full overflow-scroll gap-x-14">
        {rooms.map((each) => (
          <button
            className={` flex justify-start gap-1 items-center uppercase font-medium text-lg ${
              selectedRoom === each
                ? "font-bold"
                : "text-gray-500 hover:text-gray-900"
            }`}
            onClick={() => {
              setSelectedRoom(each);
            }}
          >
            {selectedRoom === each ? <Circle size={10} fill="black" /> : ""}
            {each}
          </button>
        ))}
      </div>
      {loading ? (
        <LoadingDiv />
      ) : cameraData?.data?.length > 0 ? (
        <div className="grid lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-1 gap-6">
          {cameraData.data.map((each) => (
            <Link
              className=" rounded-xl shadow px-3 py-4 bg-white grid gap-y-2 border-white cursor-pointer hover:bg-slate-100"
              to={`/cameras/subscription/${each?.id}`}
            >
              <p className="font-bold text-lg">{each?.inventory_serial}</p>

              <p className="text-gray-900 text-sm font-medium">
                {moment(each?.created_at).format("lll")}
              </p>
            </Link>
          ))}
        </div>
      ) : (
        <div>No Cameras Found</div>
      )}
    </ContentWrapper>
  );
};

export default MyHome;
