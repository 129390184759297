import { subscriberKycConstants } from "../constants";
export function createSubscriberKyc
    (state = {error: null, success: null, processing: false}, action) {

    switch (action.type) {
        case `${subscriberKycConstants.ADD_SUBSCRIBERS_SUCCESS}`:
            return {...state, ...{success: action.success, error: null}};

        case `${subscriberKycConstants.ADD_SUBSCRIBERS_ERROR}`:
            return {...state, ...{success: null, error: action.error}};

        case `${subscriberKycConstants.ADD_SUBSCRIBERS_PROCESSING}`:
            if (action.processing)
                return {...state, ...{processing: action.processing, success: null, error: null}}
            else
                return {...state, ...{processing: action.processing}};

        default:
            return state;
    }
}



export function getSubscriberKyc
    (state = {error: null, success: null, processing: false}, action) {

    switch (action.type) {
        
        case `${subscriberKycConstants.FETCH_SUBSCRIBER_SUCCESS}`:

            return {...state, ...{success: action?.payload?.data,processing:false, error: null}};

        case `${subscriberKycConstants.FETCH_SUBSCRIBER_ERROR}`:
            return {...state, ...{success: null,processing:false, error: action.error}};

        case `${subscriberKycConstants.FETCH_SUBSCRIBER_PROCESSING}`:
            if (action.processing)
                return {...state, ...{processing: action.processing, success: null, error: null}}
            else
                return {...state, ...{processing: action.processing}};

        default:
            return state;
    }
}



export function UpdateSubscriberKyc
    (state = {error: null, success: null, processing: false}, action) {

    switch (action.type) {
        case `${subscriberKycConstants.EDIT_SUBSCRIBERS_SUCCESS}`:
            return {...state, ...{success: action.success,processing:false, error: null}};
            
        case `${subscriberKycConstants.EDIT_SUBSCRIBERS_ERROR}`:
            return {...state, ...{success: null, error: action.error}};

        case `${subscriberKycConstants.EDIT_SUBSCRIBERS_PROCESSING}`:
                return {...state, ...{processing: action.processing, success: null, error: null}}
        default:
            return state;
    }
}