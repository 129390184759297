import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ContentWrapper from "../components/ContentWrapper";
import { useEffect } from "react";
import { getPrivacyPolicy } from "../state/actions/profile-actions";
import LoadingDiv from "../components/LoadingDiv";

const PrivacyPolicy = () => {

  const { policy, loaders  } = useSelector(state => ({
    policy: state?.profile?.policy,
    loaders: state?.profile?.loaders,
  }),shallowEqual );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return (
    <ContentWrapper title={"Privacy Policy"}>
      {loaders && Object.keys(policy).length < 1 ? (
        <LoadingDiv />
      ) : (
        <div
          className="p-4 text-white whitespace-pre-line bg-black opacity-60 rounded-lg"
          dangerouslySetInnerHTML={{ __html: policy?.data[0]?.content }}
        ></div>
      )}
    </ContentWrapper>
  );
};

export default PrivacyPolicy;
