import { toast } from "react-toastify";
import { profileConstants } from "../constants";
import { processing, success, failure } from "./axios";
import axios from "axios";

const { API, FCM_API, RESOURCE_API } = profileConstants;

export const changePassword = (data, successFunc) => {
  let url = `${API}/edit-password`;
  return (dispatch) => {
    dispatch(processing(profileConstants.CHANGE_PASSWORD_PROCESSING, true));
    return axios
      .post(url, data)
      .then((response) => {
        dispatch(success(profileConstants.CHANGE_PASSWORD_SUCCESS, response));
        toast.success(response?.data?.message || "Password Updated", {
          theme: "colored",
        });
        if (successFunc) {
          successFunc();
        }
        return response;
      })
      .catch((error) => {
        dispatch(failure(profileConstants.CHANGE_PASSWORD_ERROR, error));
        toast.error(error?.data?.message || "Password Failed to update", {
          theme: "colored",
        });
        return error;
      });
  };
};

export const getProfileData = () => {
  const finalURL = `${API}/details`;

  return (dispatch) => {
    dispatch(processing(profileConstants.GET_PROFILE_DATA_PROCESSING, true));
    return axios.get(finalURL).then(
      (response) => {
        dispatch(success(profileConstants.GET_PROFILE_DATA_SUCCESS, response));
        return response;
      },
      (error) => {
        dispatch(failure(profileConstants.GET_PROFILE_DATA_ERROR, error));
        return error;
      }
    );
  };
};


export const editProfile = (id, data) => {
  const url = `${API}/details`;
  return (dispatch) => {
    dispatch(processing(profileConstants.UPDATE_PROFILE_DATA_PROCESSING, true));
    return axios
      .patch(url, data)
      .then((response) => {
        dispatch(success(profileConstants.UPDATE_PROFILE_DATA_SUCCESS, response));
        toast.success(response?.data?.message, {
          theme: "colored",
        });
        return response;
      })
      .catch((error) => {
        dispatch(failure(profileConstants.UPDATE_PROFILE_DATA_ERROR, error));
        toast.error(error?.data?.message, {
          theme: "colored",
        });
        return error;
      });
  };
}




















export const postFcmToken = ({ config }) => {
  let url = `${FCM_API}/tokens`;
  return (dispatch) => {
    dispatch(processing(profileConstants.POST_FCM_TOKEN_PROCESSING, true));
    return axios.post(url, config).then(
      (response) => {
        dispatch(success(profileConstants.POST_FCM_TOKEN_SUCCESS, response));
        localStorage.setItem("fcm_token", config?.token);
        localStorage.setItem("device_id", config?.device_id);

        return response;
      },
      (error) => {
        dispatch(failure(profileConstants.POST_FCM_TOKEN_ERROR, error));
        return error;
      }
    );
  };
};

export const deleteFcmToken = ({ config, device_id, endFunc }) => {
  let url = `${FCM_API}/tokens/devices/${device_id}`;
  return (dispatch) => {
    dispatch(processing(profileConstants.DELETE_FCM_TOKEN_PROCESSING, true));
    return axios.post(url, config).then(
      (response) => {
        dispatch(success(profileConstants.DELETE_FCM_TOKEN_SUCCESS, response));
        localStorage.removeItem("fcm_token");
        localStorage.removeItem("device_id");
        if (endFunc) {
          endFunc();
        }
        return response;
      },
      (error) => {
        dispatch(failure(profileConstants.DELETE_FCM_TOKEN_ERROR, error));
        if (endFunc) {
          endFunc();
        }
        return error;
      }
    );
  };
};

export const loadAllNotifications = ({ query, id }) => {
  const finalURL = `${FCM_API}/users/${id}/notifications?${query ? query : ""}`;
  return (dispatch) => {
    dispatch(processing(profileConstants.GET_NOTIFICATIONS_PROCESSING, true));
    return axios.get(finalURL).then(
      (response) => {
        dispatch(success(profileConstants.GET_NOTIFICATIONS_SUCCESS, response));
        return response;
      },
      (error) => {
        dispatch(failure(profileConstants.GET_NOTIFICATIONS_ERROR, error));
        return error;
      }
    );
  };
};

export const setSeen = ({ user_id, config, notif_id, successFunc }) => {
  let url = `${FCM_API}/users/${user_id}/notifications/${notif_id}`;
  return (dispatch) => {
    dispatch(processing(profileConstants.SET_SEEN_PROCESSING, true));
    return axios.patch(url, config).then(
      (response) => {
        dispatch(success(profileConstants.SET_SEEN_SUCCESS, response));
        if (successFunc) {
          successFunc();
        }
        return response;
      },
      (error) => {
        dispatch(failure(profileConstants.SET_SEEN_ERROR, error));
        return error;
      }
    );
  };
};

export const getPrivacyPolicy = () => {
  const finalURL = `${RESOURCE_API}/admin/public/services/v1/privacy-policy`;

  return (dispatch) => {
    dispatch(processing(profileConstants.GET_PRIVACY_POLICY_PROCESSING, true));
    return axios.get(finalURL).then(
      (response) => {
        dispatch(
          success(profileConstants.GET_PRIVACY_POLICY_SUCCESS, response)
        );
        return response;
      },
      (error) => {
        dispatch(failure(profileConstants.GET_PRIVACY_POLICY_ERROR, error));
        return error;
      }
    );
  };
};

export const setServerError = (payload) => ({
  type: profileConstants.SET_SERVER_ERROR,
  payload: payload,
});

export const setFcmToken = (payload) => ({
  type: profileConstants.SET_FCM_TOKEN,
  payload: payload,
});

export const setNewNotification = (payload) => ({
  type: profileConstants.SET_NEW_NOTIFICATION,
  payload: payload,
});

export const setNotificationData = (payload) => ({
  type: profileConstants.SET_NOTIFICATION_DATA,
  payload: payload,
});

export const setPageLoading = (payload) => ({
  type: profileConstants.SET_PAGE_LOADING,
  payload: payload,
});
