import React from "react";
import { AlertCircle } from "react-feather";
import { Link } from "react-router-dom";
import AuthWrapper from "../pages/login/AuthWrapper";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="flex items-center justify-center ">
          <div className="md:min-w-[400px] mt-6">
            <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-3xl xl:p-0">
              <div className="p-4 flex flex-col items-center justify-center">
                <div className="flex gap-4 text-2xl items-center mb-4">
                  <AlertCircle strokeWidth={3} />
                  <h1>Something went wrong.</h1>
                </div>
                <p className="text-red-600">
                  {this.state.error && this.state.error.toString()}
                </p>
                <br />
                <details style={{ whiteSpace: "pre-wrap" }}>
                  {this.state.errorInfo.componentStack}
                </details>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
