import { API_URL, FCM_URL } from "../../constants";

const TENANT_ID = localStorage.getItem("tenant_id");

export const profileConstants = {
  /* Misc */
  API: `${API_URL}/subscribers/account/v1`,
  FCM_API: `${FCM_URL}/tenant/fcm/v1/tenants/${TENANT_ID}`,
  RESOURCE_API: API_URL,

  SET_PASSWORD_DATA: "SET_PASSWORD_DATA",

  /* Reducers */
  CHANGE_PASSWORD_PROCESSING: "CHANGE_PASSWORD_PROCESSING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  LOAD_PROFILE_PROCESSING: "LOAD_PROFILE_PROCESSING",
  LOAD_PROFILE_SUCCESS: "LOAD_PROFILE_SUCCESS",
  LOAD_PROFILE_ERROR: "LOAD_PROFILE_ERROR",

  GET_PROFILE_DATA_PROCESSING: "GET_PROFILE_DATA_PROCESSING",
  GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
  GET_PROFILE_DATA_ERROR: "GET_PROFILE_DATA_ERROR",

  UPDATE_PROFILE_DATA_PROCESSING: "UPDATE_PROFILE_DATA_PROCESSING",
  UPDATE_PROFILE_DATA_SUCCESS: "UPDATE_PROFILE_DATA_SUCCESS",
  UPDATE_PROFILE_DATA_ERROR: "UPDATE_PROFILE_DATA_ERROR",


  POST_FCM_TOKEN_PROCESSING: "POST_FCM_TOKEN_PROCESSING",
  POST_FCM_TOKEN_SUCCESS: "POST_FCM_TOKEN_SUCCESS",
  POST_FCM_TOKEN_ERROR: "POST_FCM_TOKEN_ERROR",

  DELETE_FCM_TOKEN_PROCESSING: "DELETE_FCM_TOKEN_PROCESSING",
  DELETE_FCM_TOKEN_SUCCESS: "DELETE_FCM_TOKEN_SUCCESS",
  DELETE_FCM_TOKEN_ERROR: "DELETE_FCM_TOKEN_ERROR",

  GET_NOTIFICATIONS_PROCESSING: "GET_NOTIFICATIONS_PROCESSING",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_ERROR: "GET_NOTIFICATIONS_ERROR",

  SET_SEEN_PROCESSING: "SET_SEEN_PROCESSING",
  SET_SEEN_SUCCESS: "SET_SEEN_SUCCESS",
  SET_SEEN_ERROR: "SET_SEEN_ERROR",

  SET_FCM_TOKEN: "SET_FCM_TOKEN",
  SET_NEW_NOTIFICATION: "SET_NEW_NOTIFICATION",
  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",

  GET_PRIVACY_POLICY_PROCESSING: "GET_PRIVACY_POLICY_PROCESSING",
  GET_PRIVACY_POLICY_SUCCESS: "GET_PRIVACY_POLICY_SUCCESS",
  GET_PRIVACY_POLICY_ERROR: "GET_PRIVACY_POLICY_ERROR",

  SET_SERVER_ERROR: "SET_SERVER_ERROR",
  SET_PAGE_LOADING: "SET_PAGE_LOADING",
};
