import { API_URL, FCM_URL } from "../../constants";

export const camerasConstants = {
  /* Misc */
  API: `${API_URL}/subscribers/inventory/v1`,
  SERVICES: `${API_URL}/subscribers/service/v1`,
  PACKAGE: `${API_URL}/subscribers/package/v1`,

  /* Reducers */
  GET_CAMERAS_PROCESSING: "GET_CAMERAS_PROCESSING",
  GET_CAMERAS_SUCCESS: "GET_CAMERAS_SUCCESS",
  GET_CAMERAS_ERROR: "GET_CAMERAS_ERROR",

  GET_PACKAGE_PROCESSING: "GET_PACKAGE_PROCESSING",
  GET_PACKAGE_SUCCESS: "GET_PACKAGE_SUCCESS",
  GET_PACKAGE_ERROR: "GET_PACKAGE_ERROR",

  GET_SUBSCRIPTIONS_PROCESSING: "GET_SUBSCRIPTIONS_PROCESSING",
  GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_ERROR: "GET_SUBSCRIPTIONS_ERROR",

  GET_PACKAGE_PLANS_PROCESSING: "GET_PACKAGE_PLANS_PROCESSING",
  GET_PACKAGE_PLANS_SUCCESS: "GET_PACKAGE_PLANS_SUCCESS",
  GET_PACKAGE_PLANS_ERROR: "GET_PACKAGE_PLANS_ERROR",

  ADD_PACKAGE_PROCESSING: "ADD_PACKAGE_PROCESSING",
  ADD_PACKAGE_SUCCESS: "ADD_PACKAGE_SUCCESS",
  ADD_PACKAGE_ERROR: "ADD_PACKAGE_ERROR",
};
