export const API_URL = process.env.REACT_APP_BASE_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const AUTH_URLS = process.env.REACT_APP_AUTH_URLS;

export const FCM_URL = process.env.REACT_APP_FCM_URL;

export const GOOGLE_CLIENT_URL = process.env.REACT_APP_GOOGLE_LOGIN;
export const FB_CLIENT_URL = process.env.REACT_APP_FB_LOGIN;
export const APPLE_CLIENT_URL = process.env.REACT_APP_APPLE_LOGIN;

export const SOCIAL_LOGIN= process.env.REACT_APP_SOCIAL_LOGIN
export const NODE_ENV=process.env.REACT_APP_NODE_ENV 