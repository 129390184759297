import { toast } from "react-toastify";
import { alertConstants } from "../constants";

function success(message, data = null) {
  toast.success(message, { theme: "colored" });
  return { type: alertConstants.SUCCESS, message, data };
}

function error(message) {
  // if (message && message.includes("Deadlock found when trying to get lock")) {
  //   //
  // } else {
  //   toast.error(message, { theme: "colored" });
  // }
  toast.error(message, { theme: "colored" });

  return { type: alertConstants.ERROR, message };
}

function warning(message) {
  toast.warn(message, { theme: "colored" });

  return { type: alertConstants.WARNING, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function reset() {
  return { type: alertConstants.RESET };
}

export {
  success as successAlert,
  error as errorAlert,
  clear as clearAlerts,
  reset as resetAlert,
  warning as warningAlert,
};
