import { toast } from "react-toastify";
import { camerasConstants } from "../constants";
import { processing, success, failure } from "./axios";
import axios from "axios";

const { API, SERVICES, PACKAGE } = camerasConstants;

export const getCameras = ({id,limit}) => {
  let url = `${API}/ipcam/${id}/ipcams`;
  const params = {
    limit:limit || 15,
    page:1
  }
 
  return (dispatch) => {
    dispatch(processing(camerasConstants.GET_CAMERAS_PROCESSING, true));
    return axios
      .get(url,{params})
      .then((response) => {
        dispatch(success(camerasConstants.GET_CAMERAS_SUCCESS, response));

        return response;
      })
      .catch((error) => {
        dispatch(failure(camerasConstants.GET_CAMERAS_ERROR, error));

        return error;
      });
  };
};

export const getPackages = (id) => {
  let url = `${SERVICES}/${id}/packages`;
  return (dispatch) => {
    dispatch(processing(camerasConstants.GET_PACKAGE_PROCESSING, true));
    return axios
      .get(url)
      .then((response) => {
        dispatch(success(camerasConstants.GET_PACKAGE_SUCCESS, response));

        return response;
      })
      .catch((error) => {
        dispatch(failure(camerasConstants.GET_PACKAGE_ERROR, error));

        return error;
      });
  };
};

export const getPackagePlans = (id) => {
  let url = `${SERVICES}/packages/${id}/plans`;
  return (dispatch) => {
    dispatch(processing(camerasConstants.GET_PACKAGE_PLANS_PROCESSING, true));
    return axios
      .get(url)
      .then((response) => {
        dispatch(success(camerasConstants.GET_PACKAGE_PLANS_SUCCESS, response));

        return response;
      })
      .catch((error) => {
        dispatch(failure(camerasConstants.GET_PACKAGE_PLANS_ERROR, error));

        return error;
      });
  };
};

export const getSubscriptions = (subscriber_id, ipcam_id) => {
  let url = `${SERVICES}/subscriber/${subscriber_id}/ipcam/${ipcam_id}/packages`;
  return (dispatch) => {
    dispatch(processing(camerasConstants.GET_SUBSCRIPTIONS_PROCESSING, true));
    return axios
      .get(url)
      .then((response) => {
        dispatch(success(camerasConstants.GET_SUBSCRIPTIONS_SUCCESS, response));

        return response;
      })
      .catch((error) => {
        dispatch(failure(camerasConstants.GET_SUBSCRIPTIONS_ERROR, error));

        return error;
      });
  };
};

export const addPackage = ({ config }) => {
  let url = `${PACKAGE}/subscriptions/subscribe`;
  return (dispatch) => {
    dispatch(processing(camerasConstants.ADD_PACKAGE_PROCESSING, true));
    return axios
      .post(url, config)
      .then((response) => {
        dispatch(success(camerasConstants.ADD_PACKAGE_SUCCESS, response));
        window.location.href = response?.data?.redirect_url;
        // toast.success(response?.data?.message || "Subscribered successfully!", {
        //   theme: "colored",
        // });
        return response;
      })
      .catch((error) => {
        dispatch(failure(camerasConstants.ADD_PACKAGE_ERROR, error));
        toast.error(
          typeof error?.data?.message === "string"
            ? error?.data?.message
            : "Mandatory fields required!",
          {
            theme: "colored",
          }
        );
        return error;
      });
  };
};
