import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Transition from "../../src/utils/Transition";

import UserAvatar from "../assets/image/Profile.png";
import { deleteFcmToken } from "../state/actions/profile-actions";

function UserMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profileData } = useSelector((state) => ({
    profileData: state.profile?.profileData,
  }));

  //set username on tab name only on dev environment

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleNavigate = (link) => {
    navigate(link);
    setDropdownOpen(false);
  };

  const logOutHandler = () => {
    const token = localStorage.getItem("fcm_token");
    const device_id = localStorage.getItem("device_id");

    const saveData = {
      token,
    };

    sessionStorage.setItem("redirect", window.location.pathname);
    localStorage.removeItem("_Bearer");
    localStorage.removeItem("rt");
    localStorage.removeItem("tenant_id");
    localStorage.removeItem("role");
    setDropdownOpen(false);
    navigate("/login");
  };

  return (
    <div
      data-testid="profile-dropdown"
      onClick={() => setDropdownOpen(!dropdownOpen)}
      // onMouseLeave={() => setDropdownOpen(false)}
    >
      <div className="relative inline-flex">
        <button
          ref={trigger}
          className="inline-flex justify-center items-center group"
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
        >
          <img className="w-10 h-10 rounded-full" src={UserAvatar} alt="User" />
          <div className="flex items-center truncate" role="main">
            <span className="truncate ml-2 text-sm font-medium group-hover:text-slate-800">
             Hi, {profileData?.subscriber_details?.full_name}
            </span>
            <svg
              className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
              viewBox="0 0 12 12"
            >
              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
            </svg>
          </div>
        </button>

        <Transition
          className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
          data-testid="profile-dropdown-container"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            data-testid="list"
          >
            <div
              className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 cursor-pointer"
              onClick={() => handleNavigate("profile")}
            >
              <div className="font-medium text-slate-800">
                {profileData?.subscriber_details?.full_name}
              </div>
              <div className="text-xs text-slate-500 italic">
                {profileData?.username}
              </div>
            </div>
            <ul>
              <li>
                <Link
                  className="font-medium text-sm  hover:text-indigo-600 flex items-center py-1 px-3"
                  to="privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="border-t">
                <Link
                  className="font-medium text-sm  hover:text-indigo-600 flex items-center py-1 px-3"
                  to="login"
                  data-testid="logout-button"
                  onClick={() => logOutHandler()}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default UserMenu;
