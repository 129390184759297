import { loginConstants } from "../constants";

export let initState = {
  processing: false,
  error: {},
};

export const loginReducer = (state = initState, action) => {
  switch (action.type) {
    // login Reducers
    case loginConstants.LOGIN_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case loginConstants.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        processing: false,
      };
    case loginConstants.LOGIN_REQUEST_ERROR:
      return {
        ...state,
        error: { data: action?.payload?.data?.message },
        processing: false,
      };

    case loginConstants.SOCIAL_LOGIN_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case loginConstants.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        processing: false,
      };
    case loginConstants.SOCIAL_LOGIN_ERROR:
      return {
        ...state,
        error: { data: action.payload.data.message },
        processing: false,
      };

    case loginConstants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case loginConstants.FORGET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        processing: false,
      };
    case loginConstants.FORGET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        error: { data: action?.payload?.data?.message },
        processing: false,
      };
    case loginConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case loginConstants.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        processing: false,
      };
    case loginConstants.RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        error: { data: action.payload.data.message },
        processing: false,
      };
    case loginConstants.CLEAR_ALL:
      return {
        ...initState,
      };
    default:
      return state;
  }
};
